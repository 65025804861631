<template>
    <div class="modal" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Broadcast <span>Add Filter</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Select Filter</label>
                            <div class="field_wpr">
                                <multiselect v-model="form.type" v-bind="filterType" placeholder="Select Filter.."></multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="form_grp" v-if="form.type == 'tags'">
                        <div class="group_item">
                            <label class="input_label">Select Tags</label>
                            <div class="field_wpr">
                                <multiselect
                                    v-model="form.ids"
                                    mode="tags"
                                    label="name"
                                    value-prop="id"
                                    :searchable="true"
                                    :group-select="true"
                                    :options="tagsGroup"
                                    :groups="true"
                                    group-label="type"
                                    group-options="tags"
                                    :group-hide-empty="true"
                                    placeholder="Select Tags"
                                >
                                    <template v-slot:grouplabel="{ group }">
                                        <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <span>{{ option.name }}</span>
                                    </template>
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                        <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                          {{ option.name }}
                                          <span
                                            v-if="!disabled"
                                            class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                          >
                                              <span class="multiselect-tag-remove-icon"></span>
                                          </span>
                                        </div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="form_grp" v-if="form.type == 'groups'">
                        <div class="group_item">
                            <label class="input_label">Select Groups</label>
                            <div class="field_wpr">
                                <multiselect
                                    v-model="form.ids"
                                    :options="groups"
                                    placeholder="Select Groups.."
                                    mode="tags"
                                    label="name"
                                    value-prop="id"
                                    :searchable="true"
                                ></multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="form_grp" v-if="form.type == 'tabs'">
                        <div class="group_item">
                            <label class="input_label">Select Segments</label>
                            <div class="field_wpr">
                                <multiselect
                                    mode="tags"
                                    v-model="form.ids"
                                    :groups="true"
                                    :searchable="true"
                                    :options="statusesGroup"
                                    valueProp="value"
                                    label="title"
                                    placeholder="Select segment"
                                    :group-select="false"
                                    group-label="type"
                                    group-options="items"
                                    :group-hide-empty="true"
                                >
                                    <template v-slot:grouplabel="{ group }">
                                        <span class="status-tabs-header">{{ group.type }}</span>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <span>{{ option.title }}</span>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal()">Cancel</button>
                <button class="btn save_btn" @click="addFilter()">Add</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'Broadcast Filter',

        data () {
            return {
                form: {
                    type: '',
                    ids: [],
                },
                filterType:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'all', label: 'All Contacts' },
                        { value: 'tags', label: 'Has Tag(s)' },
                        { value: 'groups', label: 'In Group(s)' },
                        { value: 'tabs', label: 'In Segment(s)' },
                    ],
                },
            };
        },

        props: {
            modelValue: Boolean,
            handleAddFilter: Function,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue () {
                const vm = this;

                vm.form = { type: '', ids: [] };
            },
        },

        computed: mapState({
            tagsGroup: state => state.tagModule.tagsGroup,
            groups: state => state.groupModule.groups,
            statusesGroup: state => state.contactModule.statusesGroup,
        }),

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            addFilter () {
                const vm = this;

                vm.handleAddFilter(JSON.parse(JSON.stringify(vm.form)));
                vm.closeModal();
            },
        },
    }
</script>

<style scoped>

</style>
